<template>
  <div class="story-tile" :class="`bleed-${bleed}`">
    <div class="story-item">
      <div class="image" v-if="image.length">
        <bleed-container :bleed="bleed">
          <img :src="`${image[0].url}?w=1000`" />
        </bleed-container>
      </div>
      <div class="text mt-8 md:mt-0">
        <h3 class="large">{{ headline }}</h3>
        <div class="mt-8 md:mt-12 font-bold">
          {{ intro }}
        </div>
        <div class="mt-8 md:mt-12" v-if="article">
          <button class="focus:outline-none text-14 flex flex-row items-center text-grey-400 uppercase group hover:text-grey-500 transition-colors" @click="toggleArticle">
            View more
            <svg class="ml-4 group-hover:ml-6 transition-all" id="icon_arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
              <g id="Group_3" data-name="Group 3" transform="translate(0 0.105)">
                <rect id="Rectangle" width="18.868" height="0.769" transform="translate(0 4.511)" fill="#d8d8d8"/>
                <g id="Group" transform="translate(10.291 4.895) rotate(-45)">
                  <rect id="Rectangle_Copy" data-name="Rectangle Copy" width="6.858" height="0.762" transform="translate(-0.058 6.096)" fill="#d8d8d8"/>
                  <rect id="Rectangle_Copy_2" data-name="Rectangle Copy 2" width="6.858" height="0.762" transform="translate(6.03 6.8) rotate(-90)" fill="#d8d8d8"/>
                </g>
              </g>
            </svg>

          </button>
        </div>
      </div>
    </div>
    <div class="story-article">
      <pre class="space-y-8" v-html="article"></pre>
    </div>
  </div>
</template>

<script>
import BleedContainer from "@/components/BleedContainer";
import $ from 'jquery'
export default {
  name: 'StoryTile',
  components: {BleedContainer},
  props: {
    image: {
      type: Array,
      default () {
        return []
      }
    },
    headline: {
      type: String,
      default: null
    },
    intro: {
      type: String,
      default: null
    },
    article: {
      type: String,
      default: null
    },
    bleed: {
      type: String,
      default: 'left'
    }
  },
  methods: {
    toggleArticle () {
      $(this.$el).find('.story-article').slideToggle()
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  font-family: inherit !important;
}

.story-tile {
  margin-top: 30px;
  @media(min-width: 768px)
  {
    margin-top: 60px;
  }

  &:nth-child(even) {
    .story-item {
      @media(min-width: 768px)
      {
        box:horizontal bottom reverse;

        .text {
          padding-left: 0;
          padding-right: 30px;
        }
      }
    }
  }
}

.story-article {
  margin-top: 30px;
  display: none;
  color: #959595;

  @media(min-width: 1024px)
  {
    padding-right: 25%;
  }
}

.story-item {
  .image {
    height: 200px;

    @media(min-width: 768px)
    {
      height: 300px;
    }

    @media(min-width: 1024px)
    {
      height: 400px;
    }

    .bleed-container {
      height: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  @media(min-width: 768px)
  {
    box:horizontal bottom;
    .image {
      width: 55%;
    }
    .text {
      width: 45%;
      padding-left: 30px;
    }
  }
}
</style>
