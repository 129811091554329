import axios from "axios";

let headers = {}
if (process.env.VUE_APP_PREVIEW === 'true') {
    headers = { Authorization: 'Bearer ' + process.env.VUE_APP_CONSUMER_PREVIEW_API }
}
let deliveryUrl = `https://deliver.kontent.ai/${process.env.VUE_APP_CONSUMER_PROJECT_ID}`
if (process.env.VUE_APP_PREVIEW === 'true') {
    deliveryUrl = `https://preview-deliver.kontent.ai/${process.env.VUE_APP_CONSUMER_PROJECT_ID}`
}

export default {
    getItem (type, slug, depth) {
        return axios.get(`${deliveryUrl}/items?depth=${depth}&system.type=${type}&elements.slug=${slug}&order=system.last_modified[desc]&limit=1`, {headers: headers})
    },
    getRegionForAssociatedRegion (region) {
        return axios.get(`${deliveryUrl}/items?depth=1&system.type=region&elements.page__h1_title=${region}&order=system.last_modified[desc]&limit=1`, {headers: headers})
    }
}
