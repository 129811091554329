<template>
  <div>
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': state && region}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div class="region" v-if="state && region">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
              <router-link to="/discover" class="hover:text-white transition-colors">Discover</router-link><span class="divider inline-block px-4">&gt;</span><router-link :to="`/discover/${this.$route.params.state}`" class="hover:text-white transition-colors">{{ state.elements.page__page_title.value }}</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">{{ region.elements.name.value }}</span>
            </div>
          </div>
        </div>
        <div class="hero mt-16">
          <div class="image" v-if="region.elements.hero_image.value.length">
            <img :src="region.elements.hero_image.value[0].url">
          </div>
          <div class="foreground">
            <h1 v-if="!region.elements.region_logo.value.length">{{region.elements.name.value}}</h1>
            <div class="logo" v-else>
              <img :src="region.elements.region_logo.value[0].url">
            </div>
            <div class="tagline">
              {{region.elements.tag_line.value}}
            </div>
          </div>
        </div>

        <div class="outer-container">
          <div class="inner-container">

            <div class="main-content relative">
              <div class="row md:flex md:flex-row md:items-start">
                <div class="main flex-grow">
                  <div class="main-content">
                    <div class="state-heading h2">
                      <img width="82" src="@/assets/state-nsw.png" alt="nsw" v-if="$route.params.state === 'new-south-wales'">
                      <img width="82" src="@/assets/state-qld.png" alt="qld" v-if="$route.params.state === 'queensland'">
                      <img width="82" src="@/assets/state-sa.png" alt="sa" v-if="$route.params.state === 'south-australia'">
                      <img width="82" src="@/assets/state-tas.png" alt="tas" v-if="$route.params.state === 'tasmania'">
                      <img width="82" src="@/assets/state-vic.png" alt="vic" v-if="$route.params.state === 'victoria'">
                      <img width="82" src="@/assets/state-wa.png" alt="wa" v-if="$route.params.state === 'western-australia'">
                    </div>
                    <div class="main-content-layout">
                      <div class="left">
                        <div class="intro">
                          {{region.elements.regional_overview_intro_copy.value}}
                        </div>
                        <div class="intro-sub">
                          <pre class="space-y-8">{{region.elements.region_overview.value}}</pre>
                        </div>

                        <div @click="readMore" v-if="region.elements.regional_overview_read_more.value.length > 20" class="read-more py-6 pr-14 pl-0 relative cursor-pointer transition transition-colors text-grey-500">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 22.386 12.202" class="stroke-current block absolute right-0 transition duration-300 top-6 mt-2">
                            <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2" transform="translate(0.681 0.733)">
                              <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none" stroke="#9a9a9a" stroke-miterlimit="10" stroke-width="2"/>
                            </g>
                          </svg>
                          Read more
                        </div>
                        <div class="intro-more" v-if="region.elements.regional_overview_read_more.value.length > 20">
                          <pre class="space-y-8">{{region.elements.regional_overview_read_more.value}}</pre>
                        </div>

                        <div v-if="linkedRegion" class="linked-region-mobile mt-12">
                          <div class="text-grey-500 text-sm">Find out more about the</div>
                          <router-link :to="`/discover/${$route.params.state}/${linkedRegion.elements.slug.value}`" class="text-16">{{linkedRegion.elements.page__h1_title.value}} wine region</router-link>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <aside class="hidden md:block md:sticky md:top-40 lg:top-60 md:w-96 mt-16 lg:w-380 flex-shrink-0 md:ml-16 lg:ml-24 xl:ml-56">
<!--                  <locked-content-sidebar v-if="!$auth.isAuthenticated" />-->
                  <bleed-container bleed="right">
                    <div class="bg-grey-100 p-8 lg:p-16" v-if="$auth.isAuthenticated">
                      <div class="bleed-wrapper">
                        <region-share :region="region" />
                      </div>
                    </div>
                    <div class="bleed-wrapper">
                      <div v-if="linkedRegion" class="linked-region mt-12" :class="{'mt-24': !$auth.isAuthenticated}">
                        <div class="text-grey-500 text-sm">Find out more about the</div>
                        <router-link :to="`/discover/${$route.params.state}/${linkedRegion.elements.slug.value}`" class="text-16">{{linkedRegion.elements.page__h1_title.value}} wine region</router-link>
                      </div>
                    </div>
                  </bleed-container>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-16 md:mt-32" v-if="wineries.length">
          <div class="heading border-b border-grey-100 pb-8">
            <div class="outer-container">
              <div class="inner-container flex flex-row justify-between items-start">
                <h4 class="flex flex-row items-center">
                  Our Member Wineries
                </h4>
              </div>
            </div>
          </div>

          <div class="outer-container">
            <div class="inner-container mt-8 md:mt-20">
              <div class="wineries-list">
                <div v-for="(winery, index) in wineries" :key="`winery${index}`">
                  <a :href="winery.url" target="_blank">{{winery.name}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gallery-overlay" :class="{active: galleryVisible}">
          <div class="close" @click="galleryVisible = false">
            Close
            <svg xmlns="http://www.w3.org/2000/svg" width="36.87" height="37.816" viewBox="0 0 36.87 37.816">
              <g id="Action_Go_Back" data-name="Action_Go Back" transform="translate(1.435 1.393)">
                <rect id="Action_Go_Back_background" data-name="Action_Go Back background" width="34" height="34" fill="none"/>
                <g id="Group_5" data-name="Group 5">
                  <path id="Stroke_1" data-name="Stroke 1" d="M0,0,34,35.03" fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="4"/>
                  <path id="Stroke_3" data-name="Stroke 3" d="M34,0,0,35.03" fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="4"/>
                </g>
              </g>
            </svg>

          </div>

          <swiper class="swiper gallery-top" :options="swiperOption" ref="swiperTop">
            <swiper-slide class="slide" v-for="(image, index) in region.elements.gallery.value" :key="`gallery-slide-${index}`">
              <div class="slide-inner">
                <div class="image-container">
                  <img :src="`${image.url}?w=1000`" />
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-next swiper-button-white" slot="button-next">
              <svg id="icon_arrow" xmlns="http://www.w3.org/2000/svg" width="53" height="26" viewBox="0 0 53 26">
                <g id="Group_3" data-name="Group 3">
                  <rect id="Rectangle" width="50" height="2" transform="translate(0 12)" fill="#d8d8d8"/>
                  <g id="Group" transform="translate(27.272 13) rotate(-45)">
                    <rect id="Rectangle_Copy" data-name="Rectangle Copy" width="18" height="2" transform="translate(0 16)" fill="#d8d8d8"/>
                    <rect id="Rectangle_Copy_2" data-name="Rectangle Copy 2" width="18" height="2" transform="translate(16 18) rotate(-90)" fill="#d8d8d8"/>
                  </g>
                </g>
              </svg>
            </div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev">
              <svg id="icon_arrow_copy" data-name="icon_arrow copy" xmlns="http://www.w3.org/2000/svg" width="53" height="26" viewBox="0 0 53 26">
                <g id="Group_3" data-name="Group 3" transform="translate(0 0.272)">
                  <rect id="Rectangle" width="50" height="2" transform="translate(2.728 11.728)" fill="#d8d8d8"/>
                  <g id="Group" transform="translate(12.728 25.456) rotate(-135)">
                    <rect id="Rectangle_Copy" data-name="Rectangle Copy" width="18" height="2" transform="translate(0 0)" fill="#d8d8d8"/>
                    <rect id="Rectangle_Copy_2" data-name="Rectangle Copy 2" width="18" height="2" transform="translate(18) rotate(90)" fill="#d8d8d8"/>
                  </g>
                </g>
              </svg>
            </div>
          </swiper>

          <swiper class="swiper gallery-thumbs-carousel" :options="swiperOptionThumbs" ref="swiperThumbs">
            <swiper-slide class="slide" v-for="(image, index) in region.elements.gallery.value" :key="`gallery-slide-thumb-${index}`">
              <div class="slide-inner">
                <div class="image-container">
                  <img :src="`${image.url}?w=75`" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div class="mt-16 md:mt-32" v-if="region.elements.gallery.value.length">
          <div class="heading border-b border-grey-100 pb-8">
            <div class="outer-container">
              <div class="inner-container flex flex-row justify-between items-start">
                <h4>Photo Gallery</h4>
              </div>
            </div>
          </div>

          <div class="outer-container" v-if="region.elements.gallery.value.length">
            <div class="inner-container mt-8 md:mt-20">
              <div class="gallery-thumbs">
                <div class="thumb" v-for="(image, index) in region.elements.gallery.value" :key="`gallery-thumb-${index}`">
                  <div class="image-container">
                    <img :src="`${image.url}?w=440`" />
                    <div @click="showGallery(index)" class="clicker"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-16 md:mt-32" v-if="region.elements.video_link_1.value.length">
          <div class="heading border-b border-grey-100 pb-8">
            <div class="outer-container">
              <div class="inner-container flex flex-row justify-between items-start">
                <h4>Video Gallery</h4>
              </div>
            </div>
          </div>

          <div class="outer-container">
            <div class="inner-container mt-8 md:mt-20">
              <div class="video-list">
                <winery-video :video-url="region.elements.video_link_1.value" class="mt-16" v-if="region.elements.video_link_1.value.length" />
                <div class="video-description p-4 bg-grey-150" v-if="region.elements.video_link_1.value.length && region.elements.video_link_1_description.value.length">{{region.elements.video_link_1_description.value}}</div>
                <winery-video :video-url="region.elements.video_link_2.value" class="mt-16" v-if="region.elements.video_link_2.value.length" />
                <div class="video-description p-4 bg-grey-150" v-if="region.elements.video_link_2.value.length && region.elements.video_link_2_description.value.length">{{region.elements.video_link_2_description.value}}</div>

              </div>
            </div>
          </div>
        </div>

        <div class="mt-16 md:mt-32" v-if="region.elements.documents.value.length">
          <div class="outer-container">
            <div class="inner-container mt-8 md:mt-20">
              <downloads class="mt-16" :files="region.elements.documents.value" :region-id="region.elements.region_id.value" />
            </div>
          </div>
        </div>

        <div class="mt-16 md:mt-32" v-if="region.elements.story_1_headline.value || region.elements.story_2_headline.value || region.elements.story_3_headline.value">
          <div class="outer-container">
            <div class="inner-container mt-8 md:mt-20">
              <story-tile v-if="region.elements.story_1_headline.value" :image="region.elements.story_1_image.value" :headline="region.elements.story_1_headline.value" :intro="region.elements.story_1_intro_copy.value" :article="region.elements.story_1_article.value" />
              <story-tile v-if="region.elements.story_2_headline.value" :bleed="'right'" :image="region.elements.story_2_image.value" :headline="region.elements.story_2_headline.value" :intro="region.elements.story_2_intro_copy.value" :article="region.elements.story_2_article.value" />
              <story-tile v-if="region.elements.story_3_headline.value" :image="region.elements.story_3_image.value" :headline="region.elements.story_3_headline.value" :intro="region.elements.story_3_intro_copy.value" :article="region.elements.story_3_article.value" />
            </div>
          </div>
        </div>


        <div class="conversations mt-8 md:mt-20" v-if="$auth.isAuthenticated && events.length">
          <div class="heading border-b border-grey-100 py-4">
            <div class="outer-container">
              <div class="inner-container">
                <div class="section-heading mt-8 md:mt-16">Conversations</div>
              </div>
            </div>
          </div>
          <div class="outer-container">
            <div class="inner-container">
              <div class="results">
                <div class="bigmarker-results">
                  <div @click="setFormValues" class="bigmarker-event" v-for="(event, index) in events" :key="`mb_upcoming${index}`">
                    <component :is="'style'" type="text/css">
                      #bigmarker-conference-widget-container{{event.elements.bigmarker_id.value}} .bigmarker-widget-img-box {
                      background-image: url({{event.elements.image.value[0].url}}?w=500&auto=format);
                      }
                      #bigmarker-conference-widget-container{{event.elements.bigmarker_id.value}} .bigmarker-widget-webinar-title:after {
                      display: block;
                      content: '{{event.elements.description.value}}';
                      font-size: 14px;
                      margin-top: 10px;
                      }
                    </component>
                    <div :id="`bigmarker-conference-widget-container${event.elements.bigmarker_id.value}`"></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <br>
        </div>

        <div class="collections">
          <associated-collections class="mt-16 md:mt-0" :collections="collections" :modular-content="collectionsContent" v-if="collections.length && showCollections === 'true'" />
        </div>

<!--        <locked-content heading="Wines from our region" type="wines" v-if="!$auth.isAuthenticated && products && products.length" />-->

        <div class="collections" v-if="featuredCollections.length">
          <associated-collections class="mt-16 md:mt-0" :collections="featuredCollections" :modular-content="featuredCollectionsContent" heading="Featured collections —" :intro="null" />
        </div>

<!--        <div class="products mt-8 md:mt-20" v-if="products && products.length">-->
<!--          <div class="heading border-b border-grey-100 pb-8">-->
<!--            <div class="outer-container">-->
<!--              <div class="inner-container flex flex-row justify-between items-start">-->
<!--                <h4>Wines from our region</h4>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="outer-container">-->
<!--            <div class="inner-container product-list-results">-->
<!--              <masonry-->
<!--                  :cols="{default: 4, 1023: 3, 767:2, 400:1}"-->
<!--                  :gutter="{default: '30px'}"-->
<!--              >-->
<!--                <product-tile class="mt-12" v-for="(product, index) in products" :key="'product' + product.system.codename" :product="product" :index="index"></product-tile>-->
<!--              </masonry>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="outer-container">
          <div class="inner-container">
            <div class="content mt-16">
              <div class="text-22">View all of the wines and products from this region at the Expo</div>
              <div>
                <router-link :to="`/expo?${$store.state.winerySort}%5BrefinementList%5D%5Bregion%5D%5B0%5D=${regionName}&${$store.state.productSort}%5BrefinementList%5D%5Bregion%5D%5B0%5D=${regionName}&scroll=true`" class="btn-transparent mt-8" @click="$store.commit('setSearchView', 'wineries')">View all</router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="outer-container md:hidden" v-if="$auth.isAuthenticated">
          <div class="inner-container">
            <div class="bg-grey-100 p-8 lg:p-16 mt-16">
              <region-share :region="region" />
            </div>
          </div>
        </div>

        <region-connect :region="region" v-if="$auth.isAuthenticated" />

      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Consumer from "@/kontent/consumer";
import Kontent from "@/kontent/index";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import LoadingSpinner from "@/components/LoadingSpinner";
import WineryVideo from "@/components/WineryVideo";
import Downloads from "@/components/Downloads";
import StoryTile from "@/components/StoryTile";
import AssociatedCollections from "@/components/AssociatedCollections";
import RegionConnect from "@/components/RegionConnect";
import BleedContainer from "@/components/BleedContainer";
import RegionShare from "@/components/RegionShare";
// import ProductTile from "@/components/ProductTile";
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
import $ from 'jquery'
// import LockedContentSidebar from "@/components/LockedContentSidebar";
import Bus from '@/events/EventBus';
// import LockedContent from "@/components/LockedContent";
Vue.use(VueMasonry);
export default {
  name: 'DiscoverRegion',
  metaInfo () {
    if (!this.region) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {property: 'og:description', content: this.region.elements.page__page_description.value},
      {name: 'title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {name: 'keywords', content: this.region.elements.page__meta_keywords.value},
      {name: 'description', content: this.region.elements.page__meta_description.value.length ? this.region.elements.page__meta_description.value : this.region.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {name: 'twitter:description', content: this.region.elements.page__meta_description.value.length ? this.region.elements.page__meta_description.value : this.region.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.region.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.region.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.region.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.region.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.region.elements.page__page_type.value[0].name})
    }

    return {
      title: this.region.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {
    // ProductTile,
    RegionShare,
    BleedContainer,
    RegionConnect,
    AssociatedCollections,
    StoryTile, Downloads, WineryVideo, LoadingSpinner, Swiper, SwiperSlide },
  //components: {LoadingSpinner, BleedContainer},
  props: {},
  data () {
    return {
      region: null,
      linkedRegion: null,
      state: null,
      regionContent: null,
      galleryVisible: false,
      collections: [],
      collectionsContent: null,
      featuredCollections: [],
      featuredCollectionsContent: null,
      products: [],
      productsContent: null,
      events: [],
      eventsContent: null,
      showCollections: process.env.VUE_APP_SHOW_COLLECTIONS,
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
      }
    }
  },
  computed: {
    wineries () {
      if (this.region) {
        let w = []
        for (let i = 1; i < 100; i++) {
          if (this.region.elements['member_' + i] && this.region.elements['member_' + i].value) {
            w.push({
              name: this.region.elements['member_' + i].value.split(', ')[0],
              url: this.region.elements['member_' + i].value.split(', ')[1]
            })
          }
        }
        return w
      }
      return []
    },
    productCodenames () {
      return this.products.map((item) => {
        return item.system.codename
      })
    },
    regionName () {
      if (this.region) {
        switch (this.linkedRegion.elements.page__h1_title.value.toLowerCase()) {
          case 'hunter valley':
            return 'hunter'
          default :
            return this.linkedRegion.elements.page__h1_title.value.toLowerCase()
        }
      }
      return null
    }
  },
  methods: {
    showGallery (index) {
      this.galleryVisible = true
      this.$refs.swiperThumbs.$swiper.slideTo(index, 0, false)
      this.$refs.swiperTop.$swiper.slideTo(index, 0, false)
    },
    readMore () {
      $(this.$el).find('.read-more').slideUp(250)
      $(this.$el).find('.intro-more').slideDown(250)
    },
    showPopup() {
      Bus.$emit('show-register-popup')
    },
    setFormValues () {
      $('.bigmarker-widget-out-box').each((idx, widget) => {
        const el = $(widget)
        el.find('#new_member_first_name').val(this.$auth.user['https://australianwine.com/first_name'])
        el.find('#new_member_last_name').val(this.$auth.user['https://australianwine.com/last_name'])
        el.find('#new_member_full_name').val(this.$auth.user['https://australianwine.com/first_name'] + ' ' + this.$auth.user['https://australianwine.com/last_name'])
        el.find('#new_member_email').val(this.$auth.user.email)
        // el.find('#conference_registration_pre_conference_responses_attributes_0_response').val(this.$auth.user['https://australianwine.com/job_role'])
      })
    }
  },
  mounted () {
    // Consumer.getItem('region', this.$route.params.region, 1).then((res) => {
    //   this.region = res.data.items[0]
    //   this.regionContent = res.data.modular_content
    //   Analytics.trackPage('Discover ' + this.region.elements.page__page_title.value)
    //   this.$store.dispatch("getFavouriteProducts")
    //   this.$store.dispatch("getFavouriteWineries")
    // })

    const self = this



    Consumer.getItem('state', this.$route.params.state, 0).then((res) => {
      this.state = res.data.items[0]
    })

    Kontent.getAssociatedRegion(this.$route.params.region).then((res) => {
      this.region = res.data.items[0]
      this.regionContent = res.data.modular_content
      Analytics.trackPage('Discover ' + this.region.elements.name.value)
      if (this.$auth.isAuthenticated) {
        this.$store.dispatch("getFavouriteProducts")
        this.$store.dispatch("getFavouriteWineries")
      }

      setTimeout(function () {
        const swiperTop = self.$refs.swiperTop.$swiper
        const swiperThumbs = self.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      }, 500)

      Kontent.getAssociatedCollections(res.data.items[0].system.codename).then((res) => {
        this.collections = res.data.items
        this.collectionsContent = res.data.modular_content
      })

      Kontent.getFeaturedCollections(res.data.items[0].system.codename).then((res) => {
        this.featuredCollections = res.data.items
        this.featuredCollectionsContent = res.data.modular_content
      })

      Kontent.getAssociatedProducts(res.data.items[0].system.codename).then((res) => {
        this.products = res.data.items
        this.productsContent = res.data.modular_content
      })

      Kontent.getEventsForAssociatedRegion(res.data.items[0].system.codename).then((res) => {
        this.events = res.data.items
        this.eventsContent = res.data.modular_content

        for (let i = 0; i < this.events.length; i++) {
          const event = this.events[i];
          const bmId = event.elements.bigmarker_id.value
          const head = document.getElementsByTagName('head')[0];
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://www.bigmarker.com/widget/register_widget.js?club=WA_connect&conference=${bmId}&widget_type=image_register&series_register=&upcoming_sub_title=&live_sub_title=&rec_sub_title=&upcoming_button_text=Register&live_button_text=Register&rec_button_text=View&link_to_channel=false&widget_width=&widget_height=&enable_iframe=false&background_color=ffffff&btext_color=2d374d&link_color=ff0000&ltext_color=ffffff&redirect_to_confirmation_page=false&widget_button_registered_content=View&cid=b56aa30b39d3`;
          head.appendChild(script);
        }
      })

      if (this.region.elements.state__zone__region.value) {
        Consumer.getRegionForAssociatedRegion(this.region.elements.state__zone__region.value).then((res) => {
          this.linkedRegion = res.data.items[0]
        })
      }

    })
  }
}
</script>

<style lang="scss">
.linked-region-mobile {
  @media(min-width: 768px)
  {
    display: none;
  }
}

.linked-region {
  display: none;
  @media(min-width: 768px)
  {
    display: block;
  }
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  font-family: inherit !important;
}
.product-list-results > div > div:nth-child(even) {
  margin-top: -30px;
}

.conversations {
  .section-heading {
    font-weight: 600;
    font-size: 24px;

    @media(min-width: 768px)
    {
      font-size: 34px;
    }
  }

  .results {
    margin-top: 40px;
  }

  .bigmarker-event {
    padding: 10px;
    width: 100%;
    font-family: 'Hellix', 'sans-serif' !important;
  }

  .bigmarker-results {
    @media(min-width: 768px)
    {
      box: horizontal wrap;
      margin: -10px;

      .bigmarker-event {
        width: 50%;
        box: horizontal;

        > div {
          width: 100%;
          height: 100%;
          background-color: #121212;
        }
      }
    }
  }
  .bigmarker-widget-webinar-btn {
    display: none !important;
  }
  .bigmarker-widget-out-box {
    max-width: none !important;
    height: auto !important;
  }
  .bigmarker-widget-box {
    background-color: #121212 !important;
    border: none !important;
    color: #FFF !important;
    padding: 0 0 30px 30px !important;
    display: block !important;

    @media(min-width: 1024px)
    {
      padding: 0 0 50px 50px !important;
    }
  }
  .bigmarker-widget-title {
    display: none !important;
  }
  .bigmarker-widget-img-box {
    position: relative !important;
    padding: 0 0 36% 0 !important;
    background-size: cover !important;
    background-position: center center !important;
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: #E8490F;
      opacity: 0;
      width: 70%;
    }

    &:hover {
      &:after {
        height: 5px;
        opacity: 1;
      }
    }
  }

  .bigmarker-widget-img {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    max-width: none !important;
    width: auto !important;
    opacity: 0 !important;
  }
  .bigmarker-widget-noimg-box {
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    display: block !important;
    background: #222222 !important;
    font-family: 'Hellix', 'sans-serif' !important;
  }
  .bigmarker-widget-noimg-box-cell {
    opacity: 0.1 !important;
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    display: block !important;

    a {
      width: auto !important;
      height: auto !important;
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      display: block !important;
    }
  }
  .bigmarker-widget-webinar-title {
    margin-top: 20px !important;
    padding: 0 20px 0 0 !important;

    a {
      color: #FFF !important;
      font-family: 'Hellix', 'sans-serif' !important;
      font-size: 22px !important;
      font-weight: bold !important;
      line-height: 1.4 !important;
      transition: color 0.3s ease !important;
      @media(min-width: 1024px)
      {
        font-size: 34px !important;
      }

      &:hover {
        color: #E8490F !important;
      }
    }
  }
  .bigmarker-widget-webinar-date {
    font-size: 16px !important;
    color: #FFF !important;
    margin-top: 10px;
    font-family: 'Hellix', 'sans-serif' !important;
    padding-right: 20px !important;
    @media(min-width: 1024px)
    {
      font-size: 20px !important;
    }
  }
  .bigmarker-widget-webinar-btn {
    position: relative !important;
    padding: 0 !important;
    margin-top: 20px !important;

    //left: 30px !important;
    //right: 30px !important;
    //bottom: 30px !important;
    @media(min-width: 1024px)
    {
      //left: 50px !important;
      //bottom: 50px !important;
    }

    a {
      font-family: 'Hellix', 'sans-serif' !important;
      box-shadow: inset 0 0 0 2px #E8490F !important;
      transition: all 0.3s ease !important;
      padding: 0 20px !important;
      color: #FFF !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      height: auto !important;
      background-color: transparent !important;
      text-transform: uppercase;
      line-height: 50px !important;
      display: inline-block !important;
      width: auto !important;
      border-radius: 0 !important;

      &:hover {
        box-shadow: inset 0 0 0 6px #E8490F !important;
      }
    }
  }

  .bigmarker-widget-more-link {
    position: absolute !important;
    padding: 0 !important;
    left: 170px !important;
    bottom: 30px !important;
    display: none !important;
    @media(min-width: 1024px)
    {
      left: 200px !important;
      bottom: 50px !important;
    }

    a {
      font-family: 'Hellix', 'sans-serif' !important;
      transition: all 0.3s ease !important;
      padding: 10px 20px !important;
      color: #959595 !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      height: auto !important;
      background-color: transparent !important;
      text-transform: uppercase;
      line-height: 50px !important;
      display: block !important;
      transition: color 0.3s ease !important;

      &:hover {
        color: #FFF !important;
      }
    }
  }
  .bigmarker-widget-modal-bg {
    background-color: rgba(#000, 0.9);
    box: horizontal middle center;
  }
}
</style>

<style scoped lang="scss">

.wineries-list {

  a {
    text-decoration: underline;
  }

  div {
    margin-top: 10px;
    @media(min-width: 768px)
    {
      width: 50%;
    }

    @media(min-width: 1024px)
    {
      width: 33.333%;
    }
  }

  @media(min-width: 768px)
  {
    box: horizontal wrap;
    margin-top: -5px;
  }
}

.product-list-results {
  margin-top: 30px;
}

.products {
  h4 {
    font-size: 18px;
    font-weight: bold;
    @media(min-width: 768px)
    {
      font-size: 34px;
    }
  }
}

.hero {
  height: 400px;
  position: relative;
  box: vertical middle center;
  padding: 30px;
  background-color: #000;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(#000, 0.3);
  text-align: center;

  @media(min-width: 768px)
  {
    height: 700px;
  }

  h1 {
    font-weight: bold;
  }

  .tagline {
    font-size: 18px;
    margin-top: 10px;
    @media(min-width: 768px)
    {
      font-size: 25px;
    }
  }




  .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.8;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .foreground {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .logo {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 420px;

    img {
      width: 100%;
      max-width: 420px;
    }

    //img {
    //  display: block;
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: contain;
    //}
  }
}

.gallery-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}

.main-content {
  position: relative;
  z-index: 3;
}

.state-heading {
  padding: 30px 0;
  margin-top: -42px;
  background-color: #000;
  width: 60%;
  position: relative;
  font-weight: 600;

  @media(min-width: 768px)
  {
    width: 70%;
    margin-top: -50px;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    content: '';
    right: 100%;
    width: 9999px;
    background-color: #000;
  }

  img {
    display: block;
    margin-left: -12px;
  }
}

.main-content {
  .intro {
    font-size: 18px;
    font-weight:600;
    line-height: 1.2;
    margin-bottom:40px;

    @media(min-width: 768px) {
      font-size: 26px;
    }

    @media(min-width: 1024px) {
      font-size: 34px;
    }
  }

  .intro-sub {
    margin-top: 40px;
    color: #959595;
  }

  .read-more {
    margin-top: 40px;
  }

  .intro-more {
    margin-top: 40px;
    color: #959595;
    display: none;
  }
}

.video-list {
  max-width: 660px;
}

.gallery-thumbs {
  margin: -10px;
  box: horizontal wrap;

  .clicker {
    cursor: pointer;
    position: absolute;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: none;

    @media(min-width: 768px)
    {
      display: block;
    }
  }

  .thumb {
    width: 100%;
    padding: 10px;

    .image-container {
      width: 100%;
      padding-bottom: 56%;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media(min-width: 768px)
    {
      max-width: 220px;
    }
  }
}

.gallery-overlay {
  background-color: #121212;
  padding: 20px 0;
  box: vertical middle center;
  opacity: 0;
  visibility: hidden;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    box: horizontal middle right;
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .swiper-slide {
    opacity: 0.5;

    &.swiper-slide-active {
      opacity: 1;
    }
  }

  .image-container {
    width: 100%;
    padding-bottom: 56%;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gallery-thumbs-carousel {
    .swiper-slide {
      position: relative;

      .slide-inner {
        &:after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 0;
          background-color: red;
          z-index: 10;
          transition: all 0.2s ease;
        }
        &:before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 0;
          background-color: red;
          z-index: 10;
          transition: all 0.2s ease;
        }
      }

      &.swiper-slide-active {
        .slide-inner {
          &:after {
            height: 5px;
          }
          &:before {
            width: 5px;
          }
        }
      }

    }
  }
}



.swiper-button-next, .swiper-button-prev {
  width: 53px;

  &:after {
    display: none;
  }
}

.swiper-button-disabled {
  opacity: 0;
}

.gallery-thumbs-carousel {
  width: 100%;
  margin-top: 30px;

  .swiper-slide {
    width: 100%;
    max-width: 75px;
  }
}

.gallery-top {
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    color: #FFF;
    max-width: 1000px;

    @media(min-width: 768px)
    {
      width: 70%;
    }

    .slide-inner {
      width: 100%;
      padding: 0 40px;

      @media(min-width: 768px)
      {
        padding: 0 50px;
      }

      @media(min-width: 1200px)
      {
        padding: 0 60px;
      }

      .image-container {
        width: 100%;
        padding-bottom: 56%;
        position: relative;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #FFF;
    }
  }
}




</style>
