<template>
  <div class="winery-video">
    <youtube-video v-if="videoType === 'youtube'" :video-id="videoId" />
    <div class="vimeo-container" v-if="videoType === 'vimeo'">
      <vue-vimeo-player :video-id="videoId" player-width="100%" />
    </div>
    <div class="download w-full sm:w-1/2" v-if="videoType === 'custom'">
      <a :href="videoUrl" target="_blank" class="overflow-hidden flex flex-row w-full items-center border border-grey-100" @click="track(file.name)">
        <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
          <svg width="20" height="27" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="play" class="svg-inline--fa fa-play fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="black" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6zm-16.2 55.1l-352 208C45.6 483.9 32 476.6 32 464V47.9c0-16.3 16.4-18.4 24.1-13.8l352 208.1c10.5 6.2 10.5 21.4.1 27.6z"></path></svg>
        </div>
        <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis">Video</div>
      </a>
    </div>
  </div>
</template>

<script>
import YoutubeVideo from "@/components/YoutubeVideo";
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'WineryVideo',
  components: {YoutubeVideo, vueVimeoPlayer },
  props: {
    videoUrl: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      videoType: null,
      videoId: null
    }
  },
  mounted() {
    if (this.videoUrl.toLowerCase().indexOf('watch?v=') !== -1) {
      this.videoId = this.videoUrl.split('watch?v=')[1].substr(0, 11)
      this.videoType = 'youtube'
    } else if (this.videoUrl.toLowerCase().indexOf('youtu.be/') !== -1) {
      this.videoId = this.videoUrl.split('youtu.be/')[1].substr(0, 11)
      this.videoType = 'youtube'
    } else if (this.videoUrl.toLowerCase().indexOf('vimeo.com/user') !== -1) {
      this.videoType = 'custom'
    } else if (this.videoUrl.toLowerCase().indexOf('vimeo.com/') !== -1) {
      this.videoId = this.videoUrl.split('vimeo.com/')[1]
      this.videoType = 'vimeo'
    } else {
      this.videoType = 'custom'
    }
  }
}
</script>

<style lang="scss" scoped>
  .download {
    .icon {
      color: #000;
    }
  }
</style>

<style lang="scss">
  .vimeo-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
</style>
